<template>
  <BaseLayout :title="title" :subtitle="subtitle" bg="linear-gradient(180deg, #EFF, #F9F9F9 10%, #F9F9F9)" @click-title="onTitle" noback>
    <template #extra>
      <van-button icon="edit" :style="{width:'80px', borderRadius:'10px 0 0 10px', color: '#000'}" color="#F6F6F6" v-show="authorized" @click="onTitle"></van-button>
    </template>

    <div class="content">
      
      <van-cell class="vip" :style="{color:vip.color}" :border="false" :title="vip.name" value="会员等级及权益" to="/vip" center is-link>
        <template #icon><van-icon name="vip-card" size="40" :color="vip.color" :style="{marginRight:'10px'}"/></template>
      </van-cell>

      <van-grid class="toolbar" :border="false">
        <van-grid-item icon="gold-coin-o" :style="{color: '#3C6'}" text="我的交易" to="/my/exchange" />
        <van-grid-item icon="balance-list-o"  :style="{color: '#F69'}" text="我的订单" to="/my/order" />
        <van-grid-item icon="cluster-o" :style="{color: '#993'}" text="我的邀请"  to="/my/invite" />
        <van-grid-item icon="question-o" :style="{color: '#F90'}" text="帮助中心"  to="/help" />
      </van-grid>

      <van-row class="toolbar" gutter="10" align="center" justify="space-around" v-show="authorized">
        <van-col class="icon" span="12">
          <van-cell title="钱包" :label="'$ ' + balance" class="card" center @click="$router.push('/my/wallet')">
            <template #extra>
              <van-icon name="paid" color="#69F" size="40"/>
            </template>
          </van-cell>
        </van-col>
        <van-col class="icon" span="12">
          <van-cell title="卡券" label="领取和兑换" class="card" center @click="$toast('稍后开放')">
            <template #extra>
              <van-icon name="coupon-o" color="#F6F" size="40"/>
            </template>
          </van-cell>
        </van-col>
      </van-row>

      <van-cell-group class="menu" >
        <van-cell class="item" icon="service" title="联系客服" to="/contact" center is-link/>
        <van-cell class="item" icon="fire" title="关于我们" to="/about" center is-link/>
        <van-cell class="item" icon="diamond" title="服务条款" to="/terms" center is-link/>
        <van-cell class="item" icon="upgrade" title="检测更新" value="VER: 1.2.2105011" center is-link @click="$toast('已是最新版')"/>
      </van-cell-group>

    </div>
  </BaseLayout>
</template>

<style scoped>

.content {
  margin: 10px;
}

.vip {
  height: 60px;
  background: #333;
  border-radius: 5px;
  font-size: 16px;
  overflow: hidden;
}

.toolbar {
  margin: 20px 0px;
  font-size: 14px;
}

.card {  
  height: 80px;
  background: #FFF;
  border-radius: 5px;
  color: #666;
  font-size: 18px;
  font-weight: 600;
}

.menu {
  margin: 20px 0;
}
.item {
  color: #666;
}
</style>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  created() {
    this.$init();
  },

  computed: {
    title(){
      return !this.authorized ? "登录 / 注册" : this.nickname;
    },
    subtitle(){
      return !this.authorized ? "立即登录 马上有钱" : "UID: " + this.uid;
    },
    vip(){
      let arr = {
        "0": {
          name: "精英会员",
          color: "#CCC",
        },
        "1": {
          name: "银卡会员",
          color: "#FFF",
        },
        "2": {
          name: "金卡会员",
          color: "#FF0",
        },
        "3": {
          name: "翡翠卡会员",
          color: "#0F0",
        },
        "4": {
          name: "钻石卡会员",
          color: "#0FF",
        },
      };
      return typeof arr[this.level] != "undefined" ?  arr[this.level] :  arr[0];
    },
    
    ...mapState(["profile"]),
    ...mapGetters(["authorized", "uid", "nickname", "balance", "level"]),
  },

  methods: {
    onTitle() {
      this.$router.push(this.authorized ? "/my/profile" : "/login");
    },
    ...mapMutations(["auth"]),
  }
}
</script>
